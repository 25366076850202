<template>
  <v-app>
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import { useRouter } from "vue-router";
import { validLocales } from "@/i18n";
import { useI18n } from "vue-i18n";
import { useLocale, useTheme } from "vuetify";
import { getEnv } from "@/env";
import {useStore} from "@/store";
export default {
  name: 'App',
  setup() {
    const store = useStore()
    const { locale } = useI18n()
    const theme = useTheme()
    const vuetifyLocale = useLocale()
    const router = useRouter()
router.beforeEach(async (to, from, next) => {
      const lang = to.params.lang
        if (['et_ET', 'en_US', 'ru_RU'].includes(lang) && locale.value !== lang) {
          locale.value = lang
         vuetifyLocale.current.value = lang
        }
        const queryTheme = to.query.theme
        if (queryTheme) {
        theme.global.name.value = queryTheme
      }
      return next()
    })
    window.onloadTurnstileCallback = () => {
			store.CFTurnstileScriptLoaded=true
		};
// window.onloadTurnstileCallback = () => {
// 			/* eslint-disable no-undef */
// 			let widgetId = turnstile.render('#app', {
// 				sitekey: getEnv('VUE_APP_FRONTEND_CF_TURNSTILE_SITE_KEY'),
// 				callback: (token) => {
//           store.CFTurnstileToken=token
//           store.disableButton=false
// 				},
// 			});
    
//       store.CFTurnstileWidgetId=widgetId
// 		};
//     //reset CF turnstile every 295 seconds
// 		setInterval(() => {
// 			turnstile.reset(this.CFTurnstileWidgetId);
// 		}, 295000)

  },
  // mounted (){
	// 	window.onloadTurnstileCallback = () => {
	// 		store.CFTurnstileScriptLoaded=true
	// 	};
	// },

}

</script>
