
import { createI18n } from 'vue-i18n'
const validLocales = process.env.VUE_APP_FRONTEND_VALID_LOCALES.split(',').map(locale => locale.trim())
export const defaultLocale = process.env.VUE_APP_FRONTEND_DEFAULT_LOCALE
const userLocale = defaultLocale

const localeMapping = {
  en: 'en_US',
  et: 'et_ET',
  ru: 'ru_RU',
}
const Locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)

const messages = {}
Locales.keys().forEach((fileName) => {
  const langShort = fileName.match(/([a-zA-Z]+)(?=\.json)/)[0]
  const localeFull = localeMapping[langShort]
  if (validLocales.includes(localeFull)) {
    const langEntries = Locales(fileName)
    messages[localeFull] = {
      ...langEntries,
    }
  }
})
export default createI18n({
  locale: userLocale,
  allowComposition: true,
  fallbackLocale: defaultLocale,
  // silentFallbackWarn: process.env.VUE_APP_FRONTEND_ENVIRONMENT !== 'development',
  messages,
})
