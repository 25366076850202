import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/:lang?',
    component: () => import('@/views/Home.vue'),
  },
  {
    path: '/:lang?/:source?',
    component: () => import('@/views/Home.vue'),
  },
  {
    path: '/:lang?/:source?/maintenance',
    component: () => import('@/components/Maintenance.vue'),
  },
  {
    path: '/:lang?/:source?/mobile',
    component: () => import('@/views/MobileForm.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(({}).BASE_URL),
  routes,
})
export default router

// export default {
//   install(app, options) {
//     router.install(app)
//     router.beforeEach(async (to, from, next) => {
//       const lang = to.params.lang
//         if (['et_ET', 'en_US', 'ru_RU'].includes(lang) && i18n.locale !== lang) {
//           i18n.locale = lang
//         }
//       return next()
//     })
//   },
// }
