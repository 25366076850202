// axios
import axios from 'axios'
import { getEnv } from '@/env'

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  baseURL: getEnv('VUE_APP_FRONTEND_CONTACT_FORM_BACKEND_URL'),
  withCredentials: true,
  // timeout: 10000,
  // headers: { 'Access-Control-Allow-Origin': '*' },
})
if (process.env.APP_DEBUG === true) {
  axiosIns.defaults.headers.common['Access-Control-Allow-Origin'] = '*'
}
const url = new URL(window.location.href)
const accessToken = url.searchParams.get('token')
url.searchParams.delete('token')
history.replaceState({}, '', url.href)
axiosIns.interceptors.request.use(
  (config) => {
    // Do something before request is sent
    // const accessToken = localStorage.getItem('accessToken')
    
    // eslint-disable-next-line no-param-reassign
    if (accessToken) config.headers.Authorization = `Bearer ${accessToken}`

    return config
  },
  (error) => Promise.reject(error)
)

export default axiosIns
