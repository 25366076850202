// import axios from '../axios'
import axios from '@/plugins/axios'
import { getEnv } from '@/env'
export function getTicketTypes() {
  return axios.get('support/ticket-types').then(
    (response) => Promise.resolve(response),
    (error) => Promise.reject(error)
  )
}
export function getUserBasicData() {
  return axios.get(`${getEnv('VUE_APP_FRONTEND_API_URL')}/user/me/basic`).then(
    (response) => Promise.resolve(response),
    (error) => Promise.reject(error)
  )
}
export function getUserRoles() {
  return axios.get(`${getEnv('VUE_APP_FRONTEND_API_URL')}/user/me/roles`).then(
    (response) => Promise.resolve(response),
    (error) => Promise.reject(error)
  )
}
export function getUserPackages() {
  return axios.get(`${getEnv('VUE_APP_FRONTEND_API_URL')}/user/me/package-features`).then(
    (response) => Promise.resolve(response),
    (error) => Promise.reject(error)
  )
}
// export function getUserBasicData(token) {
//   const headers = {
//     Authorization: `Bearer ${token}`,
//   };
//   return axios.get(`${getEnv('VUE_APP_FRONTEND_API_URL')}/user/me/basic`, { headers })
//     .then((response) => Promise.resolve(response.data))
//     .catch((error) => Promise.reject(error));
// }

export function getSuggestedArticles(search, locale) {
  return axios.get('/support/help-articles?search=' + search + '&locale=' + locale).then(
    (response) => Promise.resolve(response),
    (error) => Promise.reject(error)
  )
}

export function submitRequest({
  type,
  email,
  subject,
  description,
  attachments,
  os_name,
  os_version,
  build_number,
  payment_type,
  payment_email,
  tags,
  priority,
  token
}) {
  const newAttachments = attachments.map((attachment) => {
    return Object.values(attachment)
  })

  const formData = new FormData()
  formData.append('type', type)
  formData.append('email', email)
  formData.append('subject', subject)
  formData.append('description', description)

  newAttachments.forEach((attachment) => {
    formData.append('attachments[]', attachment)
  })
  // formData.append('attachments[]', newAttachments)
  formData.append('os_name', os_name)
  formData.append('os_version', os_version)
  formData.append('build_number', build_number)
  formData.append('payment_type', payment_type)
  formData.append('payment_email', payment_email)
  formData.append('tags', tags)
  formData.append('priority', priority)
  formData.append('token', token)
  return axios.post('support/tickets', formData).then(
    (response) => Promise.resolve(response),
    (error) => Promise.reject(error)
  )
}

export function uploadFile(file, progressFunction) {
  const formData = new FormData()
  formData.append('file', file)
  formData.append('app_name', 'contact-form')
  return axios
    .post(getEnv('VUE_APP_FRONTEND_UPLOAD_API_URL'), formData, {
      onUploadProgress(e) {
        if (e.lengthComputable) {
          var progress = Math.floor((e.loaded / e.total) * 100)
          progressFunction(progress)
        }
      },
    })
    .then(
      (response) => Promise.resolve(response),
      (error) => Promise.reject(error)
    )
}
