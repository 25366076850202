
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import { config } from '@k12tech/ekool-vue-framework/src/main.js'

import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

import { EBtn } from '@k12tech/ekool-vue-framework/src/components/EBtn'
import { ECircularLoading } from "@k12tech/ekool-vue-framework/src/components/ECircularLoading";
import { EDivider } from "@k12tech/ekool-vue-framework/src/components/EDivider";
import { EIcon } from "@k12tech/ekool-vue-framework/src/components/EIcon";
import { EMenu, EMenuListItem, EMenuList } from "@k12tech/ekool-vue-framework/src/components/EMenu";
import { ESelect } from "@k12tech/ekool-vue-framework/src/components/ESelect";
import { EHint } from "@k12tech/ekool-vue-framework/src/components/EHint"
import { ESwitch } from "@k12tech/ekool-vue-framework/src/components/ESwitch";
import { ETextField } from "@k12tech/ekool-vue-framework/src/components/ETextField";
import { ETextEditor } from "@k12tech/ekool-vue-framework/src/components/ETextEditor";
const vuetify = createVuetify({
  display: {
    mobileBreakpoint: 'sm',
    thresholds: {
      xs: 0,
      sm: 340,
      md: 540,
      lg: 800,
      xl: 1280,
    },
  },
  components,
  directives,
  ...config,
  aliases: {
    EBtn,
    ECircularLoading,
    EDivider,
    EIcon,
    EMenu,
    EMenuListItem,
    EMenuList,
    ESelect,
    ESwitch,
    ETextField,
    ETextEditor,
    EHint
  }
})

export default vuetify
