export const languages = [
  {
    name: 'English',
    title: 'EN',
    value: 'en_US',
  },
  {
    name: 'Estonian',
    title: 'ET',
    value: 'et_ET',
  },
  {
    name: 'Russian',
    title: 'RU',
    value: 'ru_RU',
  },
]
export const paymentTypes = [
  {
    title: 'apple_store',
    value: 'Apple Store',
  },
  {
    title: 'google_play_store',
    value: 'Google Play Store',
  },
  {
    title: 'card',
    value: 'Card',
  },
  {
    title: 'banklink',
    value: 'Banklink',
  },
  {
    title: 'other',
    value: 'Other',
  },
]
export function trackGA4clickEvent(eventName) {
  this.$gtm.trackEvent({
    event: `GA:${eventName}`,
  })
}