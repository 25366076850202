import { defineStore } from 'pinia'
import { api } from '@/api'

export const useStore = defineStore('useStore',{
  state: () => ({
    loaderCount: 0,
    loader: false,
    busy: false,
    uploadBusy: false,
    fail: false,
    success: false,
    errors: [],
    types: {},
    articles: {},
    request: {},
    userBasicData: {},
    uploadFileSuccess: {},
    files: [],
    userPackages: [],
    userRoles:[],
    uploadFail: false,
    CFTurnstileToken: null,
		CFTurnstileWidgetId: null,
    disableButton: true,
    CFTurnstileScriptLoaded: false,
  }),
  actions: {
    startLoader() {
      this.loaderCount += 1
    },
    stopLoader() {
      this.loaderCount -= 1
    },
    resetLoader() {
    this.loaderCount = 0
    },
    enableOverlayLoader () {
      this.loader = true
    },
    disableOverlayLoader() {
      this.loader = false
    },
    async getTicketTypes() {
      this.busy = true;
      try {
        const response = await api.getTicketTypes();
        this.types = response.data.data || {};
      } catch (error) {
        this.fail = error;
      } finally {
        this.busy = false;
      }
    },
    async getUserBasicData() {
      this.busy = true;
      try {
        const response = await api.getUserBasicData();
        this.userBasicData = response.data.data || {};
      } catch (error) {
        this.fail = error;
      } finally {
        this.busy = false;
      }
    },
    async getUserRoles() {
      this.busy = true;
      try {
        const response = await api.getUserRoles();
        this.userRoles = response.data.data || {};
      } catch (error) {
        this.fail = error;
      } finally {
        this.busy = false;
      }
    },
    async getUserPackages() {
      this.busy = true;
      try {
        const response = await api.getUserPackages();
        this.userPackages = response.data.data || {};
      } catch (error) {
        this.fail = error;
      } finally {
        this.busy = false;
      }
    },
    async getSuggestedArticles({ search, locale }) {
      this.busy = true;
      try {
        const response = await api.getSuggestedArticles(search, locale);
        this.articles = response.data.data || {};
      } catch (error) {
        this.fail = error;
      } finally {
        this.busy = false;
      }
    },
    async submitRequest(requestData) {
      this.busy = true;
      try {
        const response = await api.submitRequest(requestData);
        this.success = true;
        return Promise.resolve(response);
      } catch (error) {
        this.success = false;
        this.fail = error;
        return Promise.reject(error);
      } finally {
        this.busy = false;
      }
    },
    async uploadFile(file) {
      this.uploadBusy = true;
      this.files.push(file);

      try {
        const response = await api.uploadFile(file.file, (progress) => {
          file.progress = progress;
        });

        this.files = this.files.map((f) => {
          if (f.id === file.id) {
            return {
              ...file,
              blob: URL.createObjectURL(file.file),
              success: true,
              ...response.data.data,
            };
          }
          return f;
        });
      } catch (error) {
        this.files = this.files.filter((f) => f.id !== file.id);
        return Promise.resolve(error);
      } finally {
        this.uploadBusy = false;
      }
    },
  },
  
})
